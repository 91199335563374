.container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
  }
  
  .card {
    border: 1px solid #dddddd;
    border-radius: 5px;
    padding: 20px;
    text-align: center;
    background-color: #f9f9f9;
    transition: transform 0.3s ease, background-color 0.3s ease;
  }
  
  .card:hover {
    transform: scale(1.05);
    background-color: #f1f1f1;
  }
  
  .card h3 {
    margin-top: 0;
  }
  
  .card p {
    margin: 5px 0;
  }
  
  .card button {
    background-color: #35514F;
    color: #ffffff;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 5px;
    margin-top: 10px;
  }
  
  .card button:hover {
    background-color: #2a403d;
  }