/* General styling */
.admin-user-manager {
    width: 100%;
    padding: 20px;
    background-color: #f9f9f9;
    font-family: 'Arial', sans-serif;
}

.title {
    text-align: center;
    color: #333;
    margin-bottom: 20px;
    font-size: 24px;
}

/* Search Fields Styling */
.search-fields {
    display: flex;
    justify-content: space-around;
    margin-bottom: 20px;
}

.search-input {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 30%;
}

.search-button {
    padding: 10px 20px;
    background-color: #0a4b39;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.search-button:hover {
    background-color: #083b2f;
}

/* Table styling */
.users-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
}

.users-table th, .users-table td {
    border: 1px solid #ddd;
    padding: 10px;
    text-align: left;
}

.users-table th {
    background-color: #0a4b39;
    color: white;
}

/* Admin status as checkmark or X */
.admin-status {
    font-size: 18px;
    text-align: center;
}

.admin-status.check {
    color: green;
}

.admin-status.uncheck {
    color: red;
}

/* Action buttons */
.action-buttons {
    text-align: center;
    margin-top: 20px;
}

.action-button {
    padding: 10px 20px;
    margin: 5px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    color: white;
}

.add-button {
    background-color: #0a4b39;
}

.remove-button {
    background-color: #dc3545;
}

.action-button:hover {
    opacity: 0.8;
}

/* Warning message */
.warning-message {
    text-align: center;
    margin-top: 20px;
    color: #ff0000;
}

.confirm-button, .cancel-button {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin: 5px;
}

.confirm-button {
    background-color: #0a4b39;
    color: white;
}

.cancel-button {
    background-color: #dc3545;
    color: white;
}

/* Pagination styling */
.pagination {
    display: flex;
    justify-content: center;
    list-style-type: none;
    padding: 0;
    margin: 20px 0;
}

.pagination li {
    margin: 0 5px;
    cursor: pointer;
}

.pagination li a {
    padding: 8px 16px;
    border: 1px solid #0a4b39;
    color: #0a4b39;
    border-radius: 4px;
    text-decoration: none;
    transition: background-color 0.3s ease, color 0.3s ease;
}

.pagination li a:hover {
    background-color: #0a4b39;
    color: white;
}

.pagination .active a {
    background-color: #0a4b39;
    color: white;
    border-color: #0a4b39;
}

.pagination-previous, .pagination-next {
    padding: 8px 16px;
    background-color: #f9f9f9;
    color: #0a4b39;
    text-decoration: none;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
}

.pagination-previous:hover, .pagination-next:hover {
    background-color: #0a4b39;
    color: white;
}

/* Remove double border on previous and next buttons */
.pagination li a,
.pagination-previous,
.pagination-next {
    border-width: 1px;
}

/* Responsive Design */
@media screen and (max-width: 768px) {
    .search-fields {
        flex-direction: column;
        align-items: center;
    }

    .search-input {
        width: 80%;
        margin-bottom: 10px;
    }

    .users-table th, .users-table td {
        font-size: 12px;
        padding: 8px;
    }

    .action-buttons {
        flex-direction: column;
        align-items: center;
    }

    .action-button {
        width: 100%;
        margin-bottom: 10px;
    }
}
