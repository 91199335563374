.form-container {
    width: 100%;
    max-width: 500px; 
    margin: 0 auto;
    display: inline-block;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    padding: 30px;
  }
  
.form-title {
    text-align: left;
    margin-bottom: 30px; /* Adjust the space between the title and the form as needed */
    margin-top: 0px;
}

.login-form-btn {
    visibility: hidden;
}

.form-field {
    width: 100% !important;
    padding: 0.375rem 0.75rem !important;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }

.form-container {
    margin-top: 60px;
}
