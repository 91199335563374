/* src/pages/css/PasswordResetConfirmation.css */

.confirmation-container {
    max-width: 600px;
    margin: 50px auto;
    padding: 20px;
    text-align: center;
    background-color: #0a4b39;
    color: #c9e265;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  }
  
  .confirmation-container h2 {
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .confirmation-container p {
    font-size: 18px;
  }
  