.unauthorized-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f2f2f2;
}

.unauthorized-container h1 {
    font-size: 2.5rem;
    color: #ff4d4d;
    margin-bottom: 20px;
}

.unauthorized-container p {
    font-size: 1.25rem;
    margin-bottom: 20px;
}

.unauthorized-buttons {
    display: flex;
    gap: 20px;
}

.unauthorized-buttons button {
    padding: 10px 20px;
    background-color: #007bff;
    border: none;
    border-radius: 5px;
    color: white;
    font-size: 1rem;
    cursor: pointer;
}

.unauthorized-buttons button:hover {
    background-color: #0056b3;
}
