.portfolio-description {
    padding-left: 5%;
    padding-right: 5%;
}

.portfolio-description li{
    padding-left: 2%;

}

.portfolio-button {
    padding-left: 5%;
}