.event-detail-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f8f9fa; /* Light grey background for contrast */
}

.event-detail-box {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    width: 60%;
    max-width: 800px;
    text-align: center;
}

.event-detail-box h2 {
    margin-bottom: 20px;
}

.event-detail-box p {
    margin: 10px 0;
}

.event-detail-box ul {
    list-style: none;
    padding: 0;
}

.event-detail-box li {
    background-color: #f1f1f1;
    margin: 5px 0;
    padding: 10px;
    border-radius: 5px;
}