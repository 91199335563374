/* Container styling */
.update-profile-container-game {
    max-width: 600px;
    margin: 20px auto;
    padding: 20px;
    border: 4px solid #FFF;
    border-radius: 10px;
    background-color: #FFF;
    color: #c9e265;
    font-family: 'Montserrat', sans-serif; /* A pixelated font */
    text-align: center;
    font-weight: bold;
  }
  
  /* Form styling */
  .update-profile-form-game {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .form-group-game {
    margin-bottom: 20px;
    width: 80%;
  }
  
  .form-group-game label {
    display: block;
    margin-bottom: 10px;
    font-weight: bold;
    color: #c9e265;
  }
  
  .form-control-game {
    width: 100%;
    padding: 10px;
    background-color: #0a4b39;
    color: #FFF;
    border: 2px solid #000;
    border-radius: 5px;
    font-family: 'Press Start 2P', cursive; /* Pixelated font */
  }
  
  .profile-picture-link-game {
    margin-bottom: 10px;
  }
  
  .profile-picture-link-game a {
    color: #00FFFF;
    text-decoration: none;
    font-family: 'Press Start 2P', cursive; /* Pixelated font */
  }
  
  .profile-picture-link-game a:hover {
    color: #FFD700;
  }
  
  /* Button styling */
  