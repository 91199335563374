table {
    width: 100%;
    border-collapse: collapse;
  }
  
td, th {
border: 1px solid #ccc;
padding: 10px;
text-align: left; /* Default text alignment */
}

.item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    border: 1px solid #ccc;
    margin-bottom: 10px;
  }
  
  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    font-weight: bold;
    color: white;
  }
  
  .checkmark {
    background-color: #4caf50; /* Green background for the checkmark */
  }
  
  .xmark {
    background-color: #f44336; /* Red background for the X */
  }