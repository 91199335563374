.recycling-bin {
    padding: 20px;
    width: 100%; /* Make it full width */
    max-width: 1200px;
    margin: 0 auto; /* Center the container */
}

h2 {
    text-align: center;
    margin-bottom: 20px;
}

.search-fields {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 20px;
}

.search-fields input {
    padding: 10px;
    width: 18%;
    margin-bottom: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
}

.scrollable-table {
    overflow-x: auto;
    width: 100%; /* Make sure it takes the full width */
    padding: 20px; /* Add padding */
}

.entry-table {
    width: 100%;
    border-collapse: collapse;
}

.entry-table th, .entry-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: center;
}

.entry-table th {
    background-color: #f2f2f2;
    font-weight: bold;
}

.actions {
    text-align: center;
    margin-top: 20px;
}

.recover-btn, .delete-btn {
    padding: 10px;
    background-color: #0a4b39;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-right: 10px;
}

.delete-btn {
    background-color: #f44336;
}

/* Pagination styles */
.paginationBtns {
    display: flex;
    justify-content: center;
    list-style: none;
    margin-top: 20px;
    padding-left: 0;
}

.paginationBtns a {
    padding: 8px 14px;
    margin: 5px;
    border-radius: 5px;
    border: 2px solid #0a4b39;
    cursor: pointer;
    background-color: white;
    color: #0a4b39;
    font-weight: normal;
    text-align: center;
    min-width: 40px; /* Ensure buttons have consistent size */
    display: inline-block;
    transition: background-color 0.3s, color 0.3s;
}

.paginationBtns a:hover {
    background-color: #0a4b39;
    color: white;
}

/* Active page styling */
.paginationActive a {
    background-color: #0a4b39;
    color: white;
    border-color: #0a4b39;
}

/* Disabled buttons */
.paginationDisabled a {
    color: gray;
    border-color: gray;
}
