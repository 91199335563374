/* Container styling */
.profile-container-game {
  max-width: 600px;
  margin: 20px auto;
  padding: 20px;
  border: 4px solid #FFF;
  border-radius: 10px;
  background-color: #0a4b39;
  color: #c9e265;
  font-family: 'Montserrat', sans-serif;
  text-align: center;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%; /* Ensure the container takes up the full height */
}

/* Title styling */
.profile-title {
  font-size: 24px;
  margin-bottom: 20px;
  color: #FFF;
}

/* Content styling */
.profile-content-game {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Profile picture styling */
.profile-picture-game {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  border: 3px solid #c9e265;
  margin-bottom: 20px;
  object-fit: cover; /* Ensures the image covers the container while maintaining its aspect ratio */
}

/* Profile detail styling */
.profile-detail-game {
  margin: 5px 0;
  font-size: 16px;
  color: #FFF;
}

/* Link styling (optional) */
.profile-link-game {
  color: #00FFFF;
  text-decoration: none;
  font-family: 'Press Start 2P', cursive;
}

.profile-link-game:hover {
  color: #FFD700;
}
