.header-container {
    padding: 0px;
    font-family: 'Monument', sans-serif;
}

.logout-button {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    padding-left: 5px !important;
    padding-right: 5px !important;
    margin-top: -4px;
}
