.pagination-container {
    display: flex;
    justify-content: center;
    list-style: none;
    padding: 0;
    margin-top: 20px;
  }
  
  .page-item {
    margin: 0 5px;
  }
  
  .page-link {
    padding: 10px 15px;
    border-radius: 5px;
    border: 1px solid #ccc;
    text-decoration: none;
    color: #0a4b39;
    cursor: pointer;
  }
  
  .page-link:hover {
    background-color: #f0f0f0;
  }
  
  .active .page-link {
    background-color: #0a4b39;
    color: white;
    border-color: #0a4b39;
  }
  