.verification-container {
    max-width: 600px;
    margin: 50px auto;
    padding: 20px;
    text-align: center;
    background-color: #0a4b39;
    color: #c9e265;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    font-family: 'Montserrat', sans-serif;
}

.verification-container h2 {
    font-size: 24px;
    margin-bottom: 20px;
}

.verification-container p {
    font-size: 18px;
    margin-bottom: 20px;
}

.redirect-button {
    padding: 10px 20px;
    background-color: #c9e265;
    color: #0a4b39;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.redirect-button:hover {
    background-color: #a8c850;
}

.accept-button, .reject-button, .redirect-button {
    padding: 10px 20px;
    margin: 10px;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.accept-button {
    background-color: #4CAF50; /* Green */
    color: white;
}

.reject-button {
    background-color: #f44336; /* Red */
    color: white;
}

.accept-button:hover {
    background-color: #45a049;
}

.reject-button:hover {
    background-color: #e57373;
}

.redirect-button {
    background-color: #008CBA; /* Blue */
    color: white;
}

.redirect-button:hover {
    background-color: #007B9E;
}
