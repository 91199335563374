.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

/* boostrap form style */

.form-inline {
  width: 100%;
}

.form-group {
  width: 90%;
}

.input-group {
  width: 90% !important;
}

.form-control {
  width: 80% !important;
}

span.input-group-addon {
  width: 50px !important;
}

.center {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 5%;
}

button {
  background-color: #35514F; 
  color: white; 
  border: none; 
  padding: 10px 20px; 
  border-radius: 5px !important;
}

body {
  font-family: 'Helvetica, Arial, sans-serif';
  margin: 0;
  padding: 0;
  background-color: #ffffff;
  color: #000000;
}

/* Navbar Styles */
nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #35514F;
}

nav h1 {
  color: #ffffff;
  font-size: 24px;
  text-decoration: none;
}

nav ul {
  list-style-type: none;
  display: flex;
  margin: 0;
  padding: 0;
}

nav ul li {
  margin: 0 15px;
}

nav ul li a {
  color: #ffffff;
  text-decoration: none;
  font-size: 18px;
}

.container {
  /*padding: 20px; */
  max-width: 1200px;
  margin: 0 auto;
}

.grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

.card {
  border: 1px solid #dddddd;
  border-radius: 5px;
  padding: 20px;
  text-align: center;
  transition: transform 0.3s ease;
}

.card:hover {
  transform: scale(1.05);
}

.card h3 {
  margin-top: 0;
}

.card p {
  margin: 5px 0;
}

.card button {
  background-color: #35514F;
  color: #ffffff;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
  margin-top: 10px;
}

.card button:hover {
  background-color: #2a403d;
}

.form-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #dddddd;
  border-radius: 5px;
  background-color: #f9f9f9;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #dddddd;
  font-size: 16px;
}

.form-group textarea {
  resize: vertical;
}

button {
  background-color: #35514F;
  color: #ffffff;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
  font-size: 16px;
}

button:hover {
  background-color: #2a403d;
}

.detail-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.detail-box {
  padding: 20px;
  border: 1px solid #dddddd;
  border-radius: 5px;
  background-color: #f9f9f9;
  text-align: center;
  max-width: 500px;
}

.detail-box h1 {
  margin-top: 0;
}

.detail-box p {
  margin: 10px 0;
}

.profile-container {
  max-width: 1200px;
  margin: 0 auto;
}

.profile-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.profile-table th,
.profile-table td {
  border: 1px solid #dddddd;
  padding: 8px;
}

.profile-table th {
  background-color: #f2f2f2;
}

.profile-table td {
  text-align: center;
}

.profile-table a {
  color: #35514F;
  text-decoration: none;
}

.profile-table a:hover {
  text-decoration: underline;
}

@import url('https://fonts.googleapis.com/css2?family=Carme&display=swap');


.carme-font {
  font-family: 'Carme', sans-serif;
}